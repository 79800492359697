<template>
  <Section
      id="home"
      fit-screen
      background-color="rgba(0, 0, 0, .1)"
      :background-image="require('@/assets/images/bg_0.jpg')"
  >
    <Home></Home>
  </Section>
  <Section
      id="work"
      background-color="rgba(84, 9, 6, .7)"
      :background-image="require('@/assets/images/bg_1.jpg')"
  >
    <Work></Work>
  </Section>
  <Section
      id="contact"
      fit-screen
      background-color="rgba(0, 0, 0, .3)"
      :background-image="require('@/assets/images/bg_2.jpg')"
  >
    <Contact></Contact>
  </Section>
</template>

<script>
import Home from './components/Home.vue'
import Section from './components/Section.vue'
import Work from './components/Work';
import Contact from './components/Contact';

export default {
  name: 'App',
  components: {
    Home,
    Section,
    Work,
    Contact
  }
}
</script>

<style></style>
