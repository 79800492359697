<template>
    <div class="flex flex-col justify-center text-white">
        <h1>Hörproben</h1>
        <div class="md:text-lg grid md:grid-cols-2 md:gap-x-24">
            <div v-for="(song, id) in songs" :key="id" class="mb-2 flex justify-between">
                <div class="flex items-center">
                    <div @click="toggleSong(song)" class="w-3 mr-3 cursor-pointer">
                        <img
                            v-if="!song.isPlaying"
                            src="../assets/icons/play.svg"
                            title="Play"
                        >
                        <img
                            v-if="song.isPlaying"
                            src="../assets/icons/pause.svg"
                            title="Pause"
                        >
                    </div>
                    <span>{{ song.title }}</span>
                </div>
                <span>{{ song.duration }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {Howl} from 'howler';

    export default {
        name: "Work",
        data() {
            return {
                songs: [
                    {
                        title: 'Anrufbeantworter Deutsch/Englisch',
                        file: 'AB_Deutsch_Englisch.mp3',
                        duration: '1:18',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Werbung Leiner',
                        file: 'Werbung_Leiner.mp3',
                        duration: '0:36',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Werbung LG',
                        file: 'Werbung_LG.mp3',
                        duration: '0:18',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Werbung Max Factor',
                        file: 'Werbung_Max_Factor.mp3',
                        duration: '0:25',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Werbung Creamy Chocolate',
                        file: 'Werbung_Creamy_Chocolate.mp3',
                        duration: '0:36',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Buchteaser Jugendroman',
                        file: 'Buchteaser_Jugendroman.mp3',
                        duration: '0:46',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Erklärvideo',
                        file: 'Erklärvideo.mp3',
                        duration: '0:48',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Imagefilm',
                        file: 'Imagefilm.mp3',
                        duration: '1:19',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Dokumentation Elefanten',
                        file: 'Dokumentation_Elefanten.mp3',
                        duration: '0:46',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Dokumentation Universum',
                        file: 'Dokumentation_Universum.mp3',
                        duration: '0:54',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Voice Over',
                        file: 'Voice_Over.mp3',
                        duration: '1:14',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Hörbuch',
                        file: 'Hörbuch.mp3',
                        duration: '1:24',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Synchron aufgebracht',
                        file: 'Synchron_aufgebracht.mp3',
                        duration: '0:56',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Synchron aufgewühlt/panisch',
                        file: 'Synchron_aufgewühlt_panisch.mp3',
                        duration: '0:46',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Synchron bestimmt/stark',
                        file: 'Synchron_bestimmt_stark.mp3',
                        duration: '1:19',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Synchron psycho',
                        file: 'Synchron_psycho.mp3',
                        duration: '1:19',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                    {
                        title: 'Stimmfarben und Akzente',
                        file: 'Stimmfarben_und_Akzente.mp3',
                        duration: '3:04',
                        howl: null,
                        isPlaying: false,
                        events: {
                            end: null,
                        }
                    },
                ]
            }
        },
        methods: {
            toggleSong(song) {
                song.howl = song.howl || new Howl({
                  src: [require(`../assets/audio/${song.file}`)],
                  html5: true,
                });
                if (!song.isPlaying) {
                    this.songs.forEach(s => {
                        s.isPlaying = false;
                        s.howl?.pause();
                    });
                    song.howl.play();
                    if (!song.events.end) {
                        song.events.end = song.howl.on('end', () => song.isPlaying = false);
                    }
                } else {
                    song.howl.pause();
                }
                song.isPlaying = !song.isPlaying;
            }
        }
    }
</script>

<style scoped>

</style>
