<template>
  <div class="flex flex-col justify-end items-center flex-grow text-white">
    <h1 class="font-serif text-6xl md:text-7xl text-center mb-5 drop-shadow-3xl tracking-wider">Maria Gruber</h1>
    <p class="mb-6 font-sans text-3xl md:text-4xl text-center drop-shadow-3xl tracking-wider uppercase">Sprecherin</p>
    <div class="w-full flex justify-center">
      <img
        class="drop-shadow-3xl w-20 h-20 cursor-pointer flex-shrink"
        @click="scrollDown"
        src="../assets/icons/arrow_down.svg"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  methods: {
    scrollDown() {
      const workSection = document.querySelector('#work');
      workSection.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  },
}
</script>

<style scoped>
/*  .text-shadow {
    text-4
  }*/
</style>
