<template>
    <div
        :id="id"
        class="bg-cover bg-center"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
        :class="{ 'bg-fixed': !isMobileSafari }"
    >
        <section
            class="flex justify-center"
            :style="{ backgroundColor }"
        >
            <div
                class="w-3/4 py-24 w-full sm:px-10 max-w-[60rem] flex flex-col"
                :class="{ 'min-h-screen': fitScreen }"
            >
                <slot></slot>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Section",
        props: {
            id: String,
            backgroundImage: String,
            backgroundColor: String,
            fitScreen: Boolean,
        },
        data: () => {
          return {
            isMobileSafari: false,
          }
        },
        created() {
          const ua = window.navigator.userAgent;
          const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
          this.isMobileSafari = iOS;
        }
    }
</script>

<style scoped lang="scss"></style>
