<template>
    <div class="flex-grow flex justify-center items-center relative mb-14">
        <div class="w-[40rem] flex flex-col items-center">
            <h1>Erfahrung</h1>
            <div class="resume-entry">
                <span class="font-semibold">2016-2019</span>
                <span class="addition-wrapper">
                  Schauspielausbildung
                  <span class="addition">an der Elfriede Ott Schauspielakademie</span>
                  <span class="addition text-[16px] sm:text-[23px] -mt-3 sm:-mt-1">- mit Diplom abgeschlossen</span>
                </span>
            </div>
            <div class="resume-entry">
                <span class="font-semibold">2017-2021</span>
                <span class="sm:max-w-[20rem] md:text-right">Schauspielerin in div. Theaterproduktionen</span>
            </div>
            <div class="resume-entry">
                <span class="font-semibold">2020-2021</span>
                <span class="addition-wrapper sm:max-w-[20rem] md:text-right">
                  Einjährige Ausbildung zur Mediensprecherin
                  <span class="addition">bei Katrin Daliot</span>
                </span>
            </div>
        </div>
    </div>
    <div class="footer text-lg">
        <a href="mailto:gruber.mariaandrea@gmail.com">gruber.mariaandrea@gmail.com</a>
        <a href="tel:+436604006867">Tel: +43 / (0) 660 4906867</a>
        <a href="https://soundcloud.com/mariaandreagruber" target="_blank">soundcloud.com/mariaandreagruber</a>
        <a class="lighter mt-3 lg:mt-0" href="https://www.instagram.com/killwall89/" target="_blank">Fotos von Valentin Ivanov</a>
        <div class="hidden lg:block"></div>
        <a class="lighter" href="https://www.linkedin.com/in/tobias-gruber/" target="_blank">Website von Tobias Gruber</a>
    </div>
</template>

<script>
    export default {
        name: "Work",
    }
</script>

<style scoped lang="scss">
    h1, a, span {
        @apply text-white;
    }
    .resume-entry {
        @apply w-full text-2xl md:text-3xl flex justify-between mb-10 grid sm:grid-cols-2 grid-cols-1;

        span {
            @apply text-center;
            &:first-of-type {
              @apply mb-2 md:mb-0;
            }
            &:last-of-type {
              @apply sm:text-right;
            }
            &.addition-wrapper {
              @apply flex flex-col items-center sm:items-end;
              .addition {
                @apply opacity-80 text-gray-200 mt-1 transform scale-90 translate-x-2 sm:text-right;
              }
            }
        }
    }
    .footer {
        @apply w-full grid lg:grid-cols-3 gap-x-2 gap-y-3 lg:gap-y-6;

        & > a {
            &.lighter {
                @apply text-sm opacity-80 text-gray-200;
            }
            &:nth-child(3n - 2) {
                @apply text-center lg:text-left;
            }
            &:nth-child(3n - 1) {
                @apply text-center lg:text-center;
            }
            &:nth-child(3n) {
                @apply text-center lg:text-right;
            }
        }
    }
</style>
